<template>
  <div class="home-logout">
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" class="my-swipe">
      <van-swipe-item
        ><img src="@/assets/images/index_home/fake/home_0.png" alt=""
      /></van-swipe-item>
      <van-swipe-item
        ><img src="@/assets/images/index_home/fake/home_1.png" alt=""
      /></van-swipe-item>
      <van-swipe-item
        ><img src="@/assets/images/index_home/fake/home_2.png" alt=""
      /></van-swipe-item>
      <van-swipe-item
        ><img src="@/assets/images/index_home/fake/home_3.png" alt=""
      /></van-swipe-item>
    </van-swipe>

    <!-- 导航部分 -->
    <div class="nav">
      <div class="item login-box" @click="$router.push('/my')">
        <span class="login">Login In</span>
        <img src="@/assets/images/index_home/fake/login.png" alt="" />
      </div>

      <div class="item" @click="download">
        <span class="download">Download APP</span>
        <img src="@/assets/images/index_home/fake/download.png" alt="" />
      </div>
    </div>

    <!-- 人数 -->
    <div class="people">
      <img
        src="@/assets/images/index_home/fake/download-number-icon.png"
        alt=""
      />
      <span class="words"
        >have joined:
        <span class="count">{{ downloadNumber }}</span> people</span
      >
    </div>

    <!-- 宫格 -->
    <van-grid clickable :column-num="2" :gutter="5">
      <van-grid-item
        v-for="item in images"
        :key="item.id"
        @click="
          $router.push({
            path: '/details',
            name: 'details',
            query: { detail: item },
          })
        "
      >
        <template>
          <img :src="require('@/assets' + item.img)" :alt="item.title" />
          <div class="goods">
            <p>{{ item.title }}</p>
            <span>{{ item.price }}</span>
          </div>
        </template>
      </van-grid-item>
    </van-grid>
  </div>
</template>

<script>
import request from '@/utils/request'
import { downloadApp } from '@/utils/downloadUrl'
import { isAppIos } from '@/utils/unique'
export default {
  data () {
    return {
      images: [
        {
          id: 1,
          img: '/images/index_home/fake/commodity-icon-1.png',
          title:
            'Mini Pure Gold Pixiu Six-Char-acter Proverbs Black Agate Bracelet',
          price: '₫2250.00',
          details: [
            'Simulated opal 4mm',
            'Cubic zirconia',
            '18K gold plated',
            'Brass base',
            '1.2mm band width',
            'Set of three rings'
          ]
        },
        {
          id: 2,
          img: '/images/index_home/fake/commodity-icon-2.png',
          title:
            'Ombre Birthstone Stacking Ring Set.Dainty and sublimely sparkly, this set makes the perfect personalized gift!',
          price: '₫12588.00',
          details: [
            'Glass crystal cabochons 5mm, 3.5mm',
            'Cubic zirconia',
            '14k gold plated',
            'Brass base',
            '1mm band width',
            'Set of three rings'
          ]
        },
        {
          id: 3,
          img: '/images/index_home/fake/commodity-icon-3.png',
          title: 'Starburst Opal Necklace A beautiful and delicate piece.',
          price: '₫5340.00',

          details: [
            'Simulated opal',
            'Cubic zirconia',
            'Gold vermeil ',
            'Chain length 16" - 18"',
            'Charm: 9.5mm x 5.2mm'
          ]
        },
        {
          id: 4,
          img: '/images/index_home/fake/commodity-icon-4.png',
          title: 'Camellia Flower Ring a true beauty!',
          price: '₫7169.00',
          details: [
            'Mother of pearl 10 mm',
            'Cubic zirconia 1 mm - 2.5 mm',
            '18k yellow gold plated',
            'Brass base',
            'Band width 1.5 mm',
            'Height 15 mm'
          ]
        },
        {
          id: 5,
          img: '/images/index_home/fake/commodity-icon-5.png',
          title:
            'Geometric Glam CZ Studs.Featuring rectangular and circular sparkling CZs set into yellow gold, these earrings have next level sparkle',
          price: '₫4535.00',
          details: [
            'Cubic zirconia',
            'Yellow gold',
            'Brass base',
            'Brass base',
            'Diameter 8.8 mm'
          ]
        },
        {
          id: 6,
          img: '/images/index_home/fake/commodity-icon-6.png',
          title: 'Rhapsody Marquise Ring',
          price: '₫4750.00',
          details: [
            'Cubic zirconia 10 x 5 mm - 1 mm',
            '14k yellow gold vermeil plated',
            'Sterling silver base',
            'Band width 1.1 mm'
          ]
        }
      ],
      downloadNumber: 0
    }
  },
  created () {
    this.getDownloadNumber()
    // this.getDownLoad()
  },
  methods: {
    async getDownloadNumber () {
      const {
        data: { data, ret }
      } = await request({
        method: 'get',
        url: 'users/find/downloads'
      })
      if (ret === 1) this.downloadNumber = data.count
    },

    // async getDownLoad () {
    //   const { data } = await request({
    //     method: 'get',
    //     url: 'admin/setting/system'
    //   })
    //   
    //   if (ret === 1) {
    //     
    //   }
    // },
    download () {
      if (isAppIos()) {
        this.$toast(this.$t('login.APPOnlySupportsAndroidUsersDownload'))
        return false
      }
      true || this.$route.query.id || localStorage.getItem('fromid')
        ? this.$toast('Please register to download the app !')
        : localStorage.getItem('appurl_android') || downloadApp
      //   this.$router.push('/download')
    }
  }
}
</script>

<style lang="less" scoped>
.home-logout {
  font-family: 'PingFang-Regular';
  padding-bottom: 112px;
  .my-swipe {
    height: 480px;
    img {
      width: 100%;
      height: 480px;
    }
  }

  .nav {
    display: flex;
    justify-content: space-around;
    height: 150px;
    margin: 12px;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-family: 'PingFang-Medium';
        font-size: 40px;
      }
      img {
        width: 80px;
        height: 80px;
      }
      .login {
        color: rgb(144, 0, 255);
        margin-right: 54px;
      }
      .download {
        color: rgb(122, 211, 34);
      }
    }
    .login-box {
      margin-right: 12px;
    }
  }

  .people {
    margin: 12px;
    display: flex;
    align-items: center;
    img {
      width: 180px;
      height: 84px;
    }
    .words {
      font-size: 40px;
      color: rgb(44, 44, 44);
      .count {
        font-size: 48px;
        color: rgb(144, 0, 255);
      }
    }
  }

  /deep/ .van-grid-item__content {
    padding: 0 !important;
  }

  img {
    height: 355px;
  }
  .goods {
    padding: 10px;
    width: 320px;
    height: 160px;
    p {
      font-size: 22px;
      width: 100%;
      height: 100px;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
      -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
      -webkit-line-clamp: 3; /** 显示的行数 **/
      overflow: hidden; /** 隐藏超出的内容 **/
    }
    span {
      font-family: 'PingFang-Medium';
      font-size: 30px;
      color: rgb(255, 0, 0);
    }
  }
}
</style>
