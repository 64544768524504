<template>
  <div class="lottery">
    <van-grid direction="horizontal" :column-num="columnNum">
      <van-grid-item
        v-for="(item, index) in lotteryList"
        :key="index"
        :icon="item.iconUrl"
        @click="goToOrder(item)"
      >
        <div slot="text" class="words_right">
          <span class="title">{{ item.gameName }}</span>
          <!-- <span class="time">{{ item.externalId }}</span> -->
        </div>
      </van-grid-item>
    </van-grid>
  </div>
</template>

<script>
import request, { baseUrl } from '@/utils/request.js'
import { Dialog } from 'vant'
import { downloadApp } from '@/utils/downloadUrl'
import { NotLoginLinkPage } from '@/utils/storage'

export default {
  name: '',
  components: {},
  props: {
    winningList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      show: true,
      lotteryList: [],
      list: [],
      loading: false,
      finished: false,
      userInfo: localStorage.userInfo
        ? JSON.parse(window.localStorage.userInfo)
        : {},
      columnNum: 4,
      showPopup: true,
    }
  },
  mounted() {},
  created() {
    this.lotteryList = localStorage.getItem('game-lists')
      ? JSON.parse(localStorage.getItem('game-lists'))
      : []
    this.getGameLists()
  },
  methods: {
    goToOrder(item) {
      if (!NotLoginLinkPage()) {
        this.$router.push('/login')
        return false
      }
      this.getUserBalance(item)
    },
    async getGameLists() {
      const {
        data: { data, ret },
      } = await request({
        methods: 'GET',
        url: 'obgame/qm/lists',
      })
      if (ret === 1) {
        this.lotteryList = data.lists
        localStorage.setItem('game-lists', JSON.stringify(data.lists))
      } else {
        this.lotteryList = localStorage.getItem('game-lists')
          ? JSON.parse(localStorage.getItem('game-lists'))
          : []
      }
    },
    // 获取才彩票数据
    async getLottery() {
      const {
        data: { data, ret },
      } = await request({
        method: 'get',
        url: 'lottery/setting',
      })
      if (ret === 1) {
        this.lotteryList = data
        if (this.lotteryList.length && this.lotteryList.length < 4) {
          this.columnNum = this.lotteryList.length
        } else {
          this.columnNum = 4
        }
      }
    },
    async getUserBalance(item) {
      const {
        data: { data, ret },
      } = await request({
        method: 'POST',
        url: 'obgame/qm/balance',
      })
      if (ret === 1) {
        if (data.bal == 0) {
          if (data.money) {
            if (Number(data.money)) {
              // 充值游戏
              this.$emit('handleRechargeGameMoney')
            } else {
              // 
              this.$router.push('/recharge')
            }
          } else {
            // 
            this.$router.push('/recharge')
          }
        } else {
          location.href = `${baseUrl}api/obgame/qm/login?id=${
            item.id
          }&token=${localStorage.getItem('TOUTIAO_USER')}`
        }
      } else {
        // this.$toast(data.msg || 'error')
      }
    },
    isGameGoLink(item) {
      let employMoney = 0
      if (this.userInfo && Object.keys(this.userInfo).length) {
        employMoney = this.userInfo.money_plus
      } else {
        employMoney = 0
      }
      if (employMoney >= Number(item.minamount)) {
        return true
      } else {
        return false
      }
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
    },
  },
}
</script>

<style lang="less" scoped>
.lottery {
  font-family: 'PingFang-Regular';
  /deep/ .van-icon__image {
    width: 106px;
    height: 106px;
  }
  /deep/ .van-grid-item__content--horizontal {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /deep/ .van-grid-item__content {
    padding: 20px 0;
  }
  /deep/ .van-grid-item__icon img {
    border-radius: 100px;
  }
  /deep/ .words_right > .time {
    font-size: 12px;
    display: block;
  }

  .van-grid-item {
    width: 25%;
  }
  .words_right {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      color: #000;
      margin: 5px 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
    }
    .van-grid-item {
      width: 25%;
    }
    .time {
      width: 100%;
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
    }
  }
}
</style>
